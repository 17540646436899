import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css"

const Footer = () => {
    return(
        <>
            <div className="footer">
                <div className="container">
                    <div className="row">
                        {/*Column1*/}
                        <div className="column">
                            <h4>Kontakt</h4>
                            <ul>
                                <li>Adresse:</li>
                                <li>Petzenweg 13</li>
                                <li>9122 St. Kanzian am Klopeinersee</li>
                            </ul>
                            <ul>
                                <li>Telefon:</li>
                                <li><a href="tel:+436641856279">+43 664 1856279</a></li>
                            </ul>
                        </div>
                        {/*Column2*/}
                        <div className="column">
                            <h4>Wichtige Links</h4>
                            
                            <ul>
                                <li><NavLink to="/impressum">Impressum</NavLink></li>
                                <li><NavLink to="/datenschutz">Datenschutz</NavLink></li>
                                <li><NavLink to="/agb">AGB</NavLink></li>
                            </ul>
                        </div>
                        {/*Column3*/}
                    </div>
                </div>
                <div className="bot">
                    <p className="col-sm">
                        &copy;{new Date().getFullYear()} Kräuterefee vom Klopeienersee | All rights reserved
                    </p>
                </div>
            </div>
        </>
    )
}

export default Footer;