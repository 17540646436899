import React, { useState, useEffect } from "react";
import { MenuItems } from "./MenuItems"
import { Divide as Hamburger } from 'hamburger-react'
import picture from "../../assets/logo.svg";
import "./Navbar.css";
import SubMenu from "./SubMenu";
import { Link } from "react-router-dom";




const Navbar = () => {
    
        const [isOpen, setOpen] = useState(false)
        const [dimensions, setDimensions] = useState({ 
            width: window.innerWidth
          })

        useEffect(() => {
            function handleResize() {
                setDimensions({
                  width: window.innerWidth
                })
            }
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);
        
        if (isOpen && dimensions.width <= 600){
            document.body.style.overflow = "hidden"
        }else{
            document.body.style.overflow = "auto"
        }

        return (
        <>
           <nav className="nav">

                <Link to="/" className="logowrap">
                    <img src={picture} alt="Logo" className="logo"/>
                </Link>
                

                <div className="navtext">
                    <div className="navheader"><strong>WALPURGA BUCHWALD</strong><br/>Kräuterfee vom Klopeinersee</div>
                </div>

                <div className="bars div">
                    <Hamburger className="bars" toggled={isOpen} toggle={setOpen} />
                </div>

                <div className={isOpen ? 'navmenu active' : 'navmenu'}>
                    <ul className="links">
                        {MenuItems.map((item, index) => {
                        return (
                            <li>
                                <SubMenu item={item} key={index} closeMenu={setOpen}/>
                            </li>
                        )
                        
                        })}
                    </ul>
                </div>
           </nav>
        </>
           
        
        )
    
}

//<NavLink to="/signin" activeStyle>
//                    Sign In
//                </NavLink>
//                <NavBtn>
//                    <NavBtnLink to="/sign-up">Sign Up</NavBtnLink>                
//                </NavBtn>

export default Navbar;