import React from 'react';

import './index.css'
import '../App.css'
import pictureaboutme from "../assets/aboutme.png";
import pictureparagraph1 from "../assets/paragraph1.jpg";
import pictureparagraph2 from "../assets/paragraph2.png";
import pictureparagraph3 from "../assets/paragraph3.jpg";
import pictureparagraph4 from "../assets/paragraph4.jpg";
//import aboutMeImage from '../assets/AboutMeImage.JPG'




import Config from '../assets/content.json'

function Home() {
  return (
    <div>
      
      <div className="homebody" >
      
      
        <div className="welcomemessagebackground">
          <div className="welcomemessage">
              <h1 className="display-linebreak" > {Config.Homepage.WelcomeMessage}</h1>
          </div> 
        </div>
        <div className="spacer layer3"></div>
        <div className='homesections'>
          <h2>{Config.Homepage.AboutMe.Header}</h2>
          <div className='aboutme'>
            
            <div className='picturel'>
              <img src={pictureaboutme} alt="Ueber Mich" className='pciture'></img>
            </div>
            <div className='textr'>
              
              <div
                dangerouslySetInnerHTML={{__html: Config.Homepage.AboutMe.Body}}
              />
            </div>
          </div>
        </div>
          <div className="spacer layer1"></div>
          <div className="spacer layer4"></div>
          <h1 className='trenn'>{Config.Homepage.Content.Header1}</h1>
          
        <div className='homesections'>

          
            <h2>{Config.Homepage.Content.Paragraph1.Heading}</h2>
            <div className='paragraph'>
            <div className='picturer'>
              <img src={pictureparagraph1} alt="Blog" className='pciture'></img>
            </div>
            <div className='textl'>
              
              <div
                dangerouslySetInnerHTML={{__html: Config.Homepage.Content.Paragraph1.Body}}
              />
            </div>
            </div>

            
            <h2>{Config.Homepage.Content.Paragraph2.Heading}</h2>
            <div className='paragraph'>
            <div className='picturel'>
              <img src={pictureparagraph2} alt="Blog" className='pciture'></img>
            </div>
            <div className='textr'>
              
              <div
                dangerouslySetInnerHTML={{__html: Config.Homepage.Content.Paragraph2.Body}}
              />
            </div>
            </div>
        </div>
            <div className="spacer layer2"></div>
            <div className="spacer layer3"></div>
        <div className='homesections'>

            <h2>{Config.Homepage.Content.Paragraph3.Heading}</h2>
            <div className='paragraph'>
            <div className='picturer'>
              <img src={pictureparagraph3} alt="Blog" className='pciture'></img>
            </div>
            <div className='textl'>
              
              <div
                dangerouslySetInnerHTML={{__html: Config.Homepage.Content.Paragraph3.Body}}
              />
            </div>
            </div>

            <h2>{Config.Homepage.Content.Paragraph4.Heading}</h2>
            <div className='paragraph'>
            <div className='picturel'>
              <img src={pictureparagraph4} alt="Blog" className='pciture'></img>
            </div>
            <div className='textr'>
              
              <div
                dangerouslySetInnerHTML={{__html: Config.Homepage.Content.Paragraph4.Body}}
              />
            </div>
            </div>
          
        </div>
        

      </div>
      <div className="spacer layer1"></div>
    </div>
    
  );
};

export default Home;