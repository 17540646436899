import React from "react";
import './App.css';
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter as Router, Routes , Route } from "react-router-dom";
import Home from './pages/index';
import Impressum from './pages/impressum';
import Datenschutz from './pages/privacy';
import AGB from './pages/agb';
import About from './pages/about';
import Contact from './pages/contact';
import SignUp from './pages/signup';
import SignIn from './pages/signin';
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <div className="App">
    <Router>
      <div className="content-wrap">
        <Navbar/>
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} component={Home} render={() => { return (<Home/>)}}/>
            <Route path="/impressum" element={<Impressum/>}/>
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/agb" element={<AGB />} />
            <Route path="/about" element={<About />}component={About} />
            <Route path="/contact" element={<Contact />}component={Contact} />
            <Route path="/signin" element={<SignIn />}component={SignIn} />
            <Route path="/sign-up" element={<SignUp />}component={SignUp} />
            <Route render={() => <div>Sorry, page is not found :</div>} />
          </Routes>
        </div>
      </div>
      <div className="footer">
          <Footer/>
      </div>
    </Router>
    
    </div>
  );
}

export default App;