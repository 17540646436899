import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

const SubMenu = ( {item, closeMenu} ) => {

    const [subnav, setSubnav] = useState(false)

    const showSubnav = () => setSubnav(!subnav)

    

    return (
            //Decide if the dropdown is show 
            //Then if necessarry render the dropdown
        <>
            
            {item.subNav ? <div id="dropdown" onClick={showSubnav} className={item.cName}>
                <div className="link">
                        {item.title}
                        {subnav ? item.iconOpened : item.iconClosed}

                        {subnav ?
                        <div className="dropdown">
                            <ul>
                                {subnav && item.subNav.map((item,index) => {
                                    return(
                                        <li>
                                            <NavLink to={item.url} key={index} className={item.cName}  onClick={(param)=>closeMenu(!param)}>
                                                {item.title}
                                            </NavLink>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        :
                        null
                        }
                </div>
                
            </div> 
            :
            <NavLink className={item.cName} to={item.url}  onClick={(param)=>closeMenu(!param)}>{item.title}</NavLink>
            }

            
        </>
    )
}

export default SubMenu