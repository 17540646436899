import React from 'react'
import * as RiIcons from 'react-icons/ri'

export const MenuItems = [
    {
        title: 'Home',
        url: '/',
        cName: 'navlink'
    },
    //{
    //    title: 'Kurse',
    //    cName: 'navlink',
    //    iconClosed: <RiIcons.RiArrowDownSFill />,
    //    iconOpened: <RiIcons.RiArrowUpSFill />,
    //    subNav: [
    //        {
    //            title: 'Kräuterkurseeeee',
    //            url: '/about',
    //            cName: 'navlinkd'
    //        },
    //        {
    //            title: 'test2',
    //            url: '/',
    //            cName: 'navlinkd'
    //        },
    //    ]
    //},
    //{
    //    title: 'Kontakt',
    //    url: '/contact',
    //    cName: 'navlink'
    //},
]