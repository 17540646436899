import React from "react";
import "./impressum.css";

import Content from "../assets/content.json";

function Impressum() {

    return (
        <>
        <div className="main">
            <h1 className="head">{Content.Impressum.Heading}</h1>
            <h2 className="sub">{Content.Impressum.Subheading}</h2>

            {Content.Impressum.Informations.Name}<br></br>
            {Content.Impressum.Informations.Organisation}<br></br>
                <tr>
                    <td>Adresse:</td>
                    <td>{Content.Impressum.Informations.Adresse}</td>
                </tr>
                <tr>
                    <td>Tel.:</td>
                    <td>{Content.Impressum.Informations.Telefon}</td>
                </tr>
                <tr>
                    <td>E-Mail:</td>
                    <td>{Content.Impressum.Informations.Mail}</td>
                </tr>
                <tr>
                    <td>Rechtsform:</td>
                    <td>{Content.Impressum.Informations.Rechtsform}</td>
                </tr>
                <tr>
                    <td>Gerichtsstand: </td>
                    <td>{Content.Impressum.Informations.Gerichtsstand}</td>
                </tr>

            <h3>{Content.Impressum.Paragraph1.Heading}</h3>
            <p>{Content.Impressum.Paragraph1.body}</p>

            <h3>{Content.Impressum.Paragraph2.Heading}</h3>
            <p>{Content.Impressum.Paragraph2.body}</p>
        </div>
        
        </>
    )

}

export default Impressum;