import React from "react";
import "./agb.css";

import Content from "../assets/content.json";

function AGB() {

    return (
        <>
        <div className="main">
            <h1 className="head">{Content.AGB.Heading}</h1>
            <h2 className="sub">{Content.AGB.Subheading}</h2>

            <h3>{Content.AGB.Paragraph1.Heading}</h3>
            <ol>
                <li>{Content.AGB.Paragraph1.List.Item1}</li>
                <li>{Content.AGB.Paragraph1.List.Item2}</li>
                <li>{Content.AGB.Paragraph1.List.Item3}</li>
                <li>{Content.AGB.Paragraph1.List.Item4}</li>
                <li>{Content.AGB.Paragraph1.List.Item5}</li>
                <li>{Content.AGB.Paragraph1.List.Item6}</li>
            </ol>

            <h3>{Content.AGB.Paragraph2.Heading}</h3>
            <ol>
                <li>{Content.AGB.Paragraph2.List.Item1}</li>
                <li>{Content.AGB.Paragraph2.List.Item2}</li>
                <li>{Content.AGB.Paragraph2.List.Item3}</li>
            </ol>

            <h3>{Content.AGB.Paragraph3.Heading}</h3>
            <ol>
                <li>{Content.AGB.Paragraph3.List.Item1}</li>
                <li>{Content.AGB.Paragraph3.List.Item2}</li>
                <li>{Content.AGB.Paragraph3.List.Item3}</li>
            </ol>

            <h3>{Content.AGB.Paragraph4.Heading}</h3>
            <ol>
                <li>{Content.AGB.Paragraph4.List.Item1}</li>
                <li>{Content.AGB.Paragraph4.List.Item2}</li>
                <li>{Content.AGB.Paragraph4.List.Item3}</li>
                <li>{Content.AGB.Paragraph4.List.Item4}</li>
            </ol>

            <h3>{Content.AGB.Paragraph5.Heading}</h3>
            <ol>
                <li>{Content.AGB.Paragraph5.List.Item1}</li>
                <li>{Content.AGB.Paragraph5.List.Item2}</li>
                <li>{Content.AGB.Paragraph5.List.Item3}</li>
                <li>{Content.AGB.Paragraph5.List.Item4}</li>
                <li>{Content.AGB.Paragraph5.List.Item5}</li>
            </ol>

            <h3>{Content.AGB.Paragraph6.Heading}</h3>
            <ol>
                <li>{Content.AGB.Paragraph6.List.Item1}</li>
                <li>{Content.AGB.Paragraph6.List.Item2}</li>
                <li>{Content.AGB.Paragraph6.List.Item3}</li>
                <li>{Content.AGB.Paragraph6.List.Item4}</li>
            </ol>

            <h3>{Content.AGB.Paragraph7.Heading}</h3>
            <ol>
                <li>{Content.AGB.Paragraph7.List.Item1}</li>
                <li>{Content.AGB.Paragraph7.List.Item2}</li>
                <li>{Content.AGB.Paragraph7.List.Item3}</li>
                <li>{Content.AGB.Paragraph7.List.Item4}</li>
                <li>{Content.AGB.Paragraph7.List.Item5}</li>
                <li>{Content.AGB.Paragraph7.List.Item6}</li>
                <li>{Content.AGB.Paragraph7.List.Item7}</li>
            </ol>

            <h3>{Content.AGB.Paragraph8.Heading}</h3>
            <ol>
                <li>{Content.AGB.Paragraph8.List.Item1}</li>
                <li>{Content.AGB.Paragraph8.List.Item2}</li>
                <li>{Content.AGB.Paragraph8.List.Item3}</li>
                <li>{Content.AGB.Paragraph8.List.Item4}</li>
                <li>{Content.AGB.Paragraph8.List.Item5}</li>
            </ol>

            <h3>{Content.AGB.Paragraph9.Heading}</h3>
            <ol>
                <li>{Content.AGB.Paragraph9.List.Item1}</li>
                <li>{Content.AGB.Paragraph9.List.Item2}</li>
            </ol>

            <h3>{Content.AGB.Paragraph10.Heading}</h3>
            <ol>
                <li>{Content.AGB.Paragraph10.List.Item1}</li>
                <li>{Content.AGB.Paragraph10.List.Item2}</li>
            </ol>

            <h3>{Content.AGB.Paragraph11.Heading}</h3>
            <ol>
                <li>{Content.AGB.Paragraph11.List.Item1}</li>
                <li>{Content.AGB.Paragraph11.List.Item2}</li>
            </ol>

            <h3>{Content.AGB.Paragraph12.Heading}</h3>
            <p>{Content.AGB.Paragraph12.Body}</p>

            <h3>{Content.AGB.Paragraph13.Heading}</h3>
            <ol>
                <li>{Content.AGB.Paragraph13.List.Item1}</li>
                <li>{Content.AGB.Paragraph13.List.Item2}</li>
            </ol>

            <h3 className="stand">Stand: {Content.AGB.Stand}</h3>
            

        </div>
        </>
    )

}

export default AGB;