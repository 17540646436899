import React from "react";
import "./privacy.css";

import Content from "../assets/content.json";

function Datenschutz() {

    return (
        <>
        <div className="main">
            <h1 className="head">{Content.Privacy.Heading}</h1>
            <h2 className="sub">{Content.Privacy.Subheading}</h2>
            <p>{Content.Privacy.Subbody}</p>

            <h3>{Content.Privacy.Paragraph1.Heading}</h3>
            <p>{Content.Privacy.Paragraph1.Body}</p>

            <h3>{Content.Privacy.Paragraph2.Heading}</h3>
            <p>{Content.Privacy.Paragraph2.Body1}</p>
            <ul>
                <li>{Content.Privacy.Paragraph2.List.Item1}</li>
                <li>{Content.Privacy.Paragraph2.List.Item2}</li>
                <li>{Content.Privacy.Paragraph2.List.Item3}</li>
                <li>{Content.Privacy.Paragraph2.List.Item4}</li>
            </ul>
            <p>{Content.Privacy.Paragraph2.Body2}</p>

            <h3>{Content.Privacy.Paragraph3.Heading}</h3>
            <p>{Content.Privacy.Paragraph3.Body}</p>

            <h3>{Content.Privacy.Paragraph4.Heading}</h3>
            <p>{Content.Privacy.Paragraph4.Body}</p>

            <h3>{Content.Privacy.Paragraph5.Heading}</h3>
            <p>{Content.Privacy.Paragraph5.Body}</p>

            <h3>{Content.Privacy.Paragraph6.Heading}</h3>
            <p>{Content.Privacy.Paragraph6.Body}</p>

            <h3>{Content.Privacy.Paragraph7.Heading}</h3>
            <p>{Content.Privacy.Paragraph7.Body}</p>

            <h3>{Content.Privacy.Kontakdaten.Heading}</h3>
            <div className="ro">
                <div className="col">
                            Adresse:<br></br>
                            Tel.:<br></br>
                            E-Mail:

                </div>
                <div className="col">
                    {Content.Privacy.Kontakdaten.Body.Adresse}<br></br>
                    {Content.Privacy.Kontakdaten.Body.Telefon}<br></br>
                    {Content.Privacy.Kontakdaten.Body.Mail}<br></br>
                </div>
            </div>

            <h3>{Content.Privacy.Paragraph8.Heading}</h3>
            <p>{Content.Privacy.Paragraph8.Body}</p>

            <h3 className="stand">Stand: {Content.Privacy.Stand}</h3>
            
            <p className="adinf">{Content.Privacy.Zusatzinformationen}</p>
            

        </div>
        </>
    )

}

export default Datenschutz;